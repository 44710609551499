import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { first, unescape } from 'lodash'
import { isWindowAvailable } from '../util'
import { Flex } from 'rebass'
import { black } from '../theme'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import Stripe from '../components/Stripe'
import PageTitle from '../components/PageTitle'
import Router from '../components/Router'
import PageContentHolder from '../components/PageContentHolder'
import ArticleContent from '../components/ArticleContent'
import Carousel from '../components/Carousel'
import ArtistRelease from '../components/ArtistRelease'
import DownloadLink from '../components/DownloadLink'
// import MerchandiseListing from '../components/MerchandiseListing'

const ReleasesFlex = styled(Flex)`
  position: relative;
`
class ArtistDetailTemplate extends Component {
  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const currentArtistReleases = (this.props.data.allWordpressPage) ? this.props.data.allWordpressPage.edges : []
    const normalizedListing = currentArtistReleases.map((artist) => {
      const image = artist.node.acf.release_artwork
      const sizes = (image && image.localFile) ? image.localFile.childImageSharp.sizes : null
      return {
        title: artist.node.title,
        attribution: undefined,
        sizes: sizes,
        link: artist.node.link,
        releasedate: artist.node.acf.release_date
      }
    })
    const bodyStyle = (isWindowAvailable) ? { style: `background-color: ${black.mid};` } : null
    // const merch = (this.props.data.merchandise.acf.merchandise) ? this.props.data.merchandise.acf.merchandise.map((merchandise) => {
    //   if (merchandise.image) {
    //     merchandise.sizes = merchandise.image.localFile.childImageSharp.sizes
    //   }
    //   // merchandise.meta = `${first(merchByArtist[key]).node.title}`
    //   return merchandise
    // }) : null

    return (
      <Layout>
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} bodyAttributes={bodyStyle} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${currentPage.title}`} bodyAttributes={bodyStyle} />
        }
        <Router
          items={first(this.props.data.router.edges).node.items}
        />
        <PageTitle title='artists' prefix />

        <PageContentHolder heading={currentPage.title}>
          <Carousel width={1 / 1} my={[0, null, 30]} images={currentPage.acf.artist_images} />
          <ArticleContent>
            <div dangerouslySetInnerHTML={{ __html: unescape(currentPage.acf.artist_bio) }} />
            { currentPage.acf.artist_media_kit &&
            <DownloadLink assetlink={currentPage.acf.artist_media_kit.source_url}>Download media kit</DownloadLink>
            }

          </ArticleContent>

          {/* { merch &&
            <ArticleContent post vinline><MerchandiseListing items={merch} /></ArticleContent>
          } */}

          {/* Releases  */}
          <ReleasesFlex ml={[0, null, '4vw']} mt={5} mb={'-5rem'} justifyContent='flex-start' flexWrap='wrap'>
            <Stripe release />
            { normalizedListing.map((release, key) => {
              return <ArtistRelease release={release} showreleasedate key={`car:${key}`} releasekey={`${key}`} />
            })}
          </ReleasesFlex>

        </PageContentHolder>
        <Stripe top />

        <Footer />
      </Layout>
    )
  }
}

export default ArtistDetailTemplate

export const ArtistDetailQuery = graphql`
  query artistDetailQuery($id: String!, $title: String!) {
    router:allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        artist_bio
        artist_images {
          localFile {
            childImageSharp {
              sizes(
                maxWidth: 1162,
                maxHeight: 712,
                cropFocus: CENTER
              ) {
                  ...GatsbyImageSharpSizes_withWebp
                }
            }
          }
        }
      }
    }
    merchandise:wordpressPage(id: { eq: $id }) {
      title
      acf {
        merchandise {
          name
          price
          weight
          depth
          image {
            localFile {
              childImageSharp {
                sizes(
                  maxWidth: 800,
                  maxHeight: 800
                ) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
              }
            }
          }
        }
      }
    }
    

    allWordpressPage(filter: {acf: {release_artist: {elemMatch: {post_title: {eq: $title}}}}}) {
      edges {
        node {
          categories {
            name
          }
          title
          link
          acf {
            release_artist {
              post_title
            }
            release_date
            release_id
            release_artwork {
              
                localFile {
                  childImageSharp {
                    sizes(
                      maxWidth: 1500,
                    ) {
                        ...GatsbyImageSharpSizes_withWebp
                      }
                  }
                }
            }
          }
        }
      }
    }
    
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
